import React from 'react';
import Room from '../../store/models/Room';
import styled from 'styled-components/macro';
import { observer } from 'mobx-react-lite';
import { text } from '../../utils';
import roomsStore from '../../store/RoomsStore';
import CircleBadge from '../CircleBadge/CircleBadge';
import { EventAction } from '../../services/EventAction';
import { EventCategory } from '../../services/EventCategory';
import appStore from '../../store/AppStore';
import { marketplaceRooms } from '../../store/enums/MarketplaceRoomEnum';
import { Icon, IconKeys } from '@groupbuilderoy/gb-components-library';
import { useTrackers } from '../../hooks/useTrackers';

const RoomsUL = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
  padding-bottom: 1rem;
  margin-right: 3rem;

  position: relative;
  &:after {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
    border-bottom: 1px solid black;
  }
`;

const RoomLI = styled.li<{ selected?: boolean }>`
  cursor: pointer;
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  h3 {
    line-height: 1.5rem;
    max-height: 9rem;
    position: relative;
    font-family: ${({ selected }) =>
      selected ? 'var(--custom-fontNameBold)' : 'var(--custom-fontName)'};
    font-weight: ${({ selected }) => (selected ? 'bold' : 'normal')};
    text-overflow: ellipsis;
    overflow: hidden;
    text-transform: uppercase;
  }

  span {
    font-size: small;
    font-weight: ${({ selected }) => (selected ? 'bold' : 'normal')};
  }
`;

const StyledCircleBadge = styled(CircleBadge)`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(100%, -50%);
`;

interface RoomsListProps {
  rooms: Room[];
  onSelect: (roomId?: number) => void;
  generalDeadlineNear?: boolean;
  [x: string]: any;
}

const RoomsList = ({ rooms, onSelect, generalDeadlineNear, ...rest }: RoomsListProps) => {
  const { triggerEvent } = useTrackers();
  const generalAppearanceRoom = rooms.find((room) => room.ifcguid === null);

  const excludedRoomNames = ['AUKKO', 'VERKKOKAUPPA', 'MARKETPLACE'];
  const firstRooms = rooms.filter((r) => marketplaceRooms.includes(r.name));

  return (
    <RoomsUL {...rest}>
      {!!firstRooms.length &&
        firstRooms.map((firstRoom) => (
          <RoomLI
            key={firstRoom.id}
            onClick={() => {
              onSelect(firstRoom.id);
              triggerEvent({
                action: EventAction.OPEN_MARKETPLACE_ROOM,
                category: EventCategory.USER
              });
            }}
            selected={firstRoom.selected}
            style={{ marginLeft: '-9px' }}
          >
            <Icon iconKey={IconKeys.shoppingCart} size={25} />
            <h3>{firstRoom.name}</h3>
            {appStore.tenantSettings.customerJourney2_showRoomPrices &&
              Number(firstRoom.cost) > 0 && <span>{firstRoom.intlCostFormat}</span>}
            {firstRoom.roomDeadlineNear && <StyledCircleBadge>!</StyledCircleBadge>}
          </RoomLI>
        ))}
      <RoomLI
        key='common'
        onClick={() => {
          onSelect();
          triggerEvent({
            action: EventAction.SELECT_ROOM,
            category: EventCategory.USER,
            label: 'selected general appearance'
          });
        }}
        selected={roomsStore.selectedRoom === undefined}
      >
        <h3>{text('generalAppearance')}</h3>
        {appStore.tenantSettings.customerJourney2_showRoomPrices && (
          <span>{generalAppearanceRoom?.intlCostFormat}</span>
        )}
        {generalDeadlineNear && <StyledCircleBadge>!</StyledCircleBadge>}
      </RoomLI>
      {rooms
        .filter((r) => !excludedRoomNames.includes(r.name) && !r.isCommunal) // Requested from CU-861mbhgux / 8694cd0z6
        .map((room) => (
          <RoomLI
            key={room.id}
            onClick={() => {
              onSelect(room.id);
              triggerEvent({
                action: EventAction.SELECT_ROOM,
                category: EventCategory.USER
              });
            }}
            selected={room.selected}
          >
            <h3>{room.name}</h3>
            {appStore.tenantSettings.customerJourney2_showRoomPrices && Number(room.cost) > 0 && (
              <span>{room.intlCostFormat}</span>
            )}

            {room.roomDeadlineNear && <StyledCircleBadge>!</StyledCircleBadge>}
          </RoomLI>
        ))}
    </RoomsUL>
  );
};

export default observer(RoomsList);
